@font-face {
    font-family: 'Sofia';
    src: url('../fonts/SofiaProMedium.ttf') format('truetype');
  }
  
.step3-container {
    display: flex;
    justify-content: space-between;
    /* font-family: 'Sofia Pro', Arial, sans-serif; */
    flex-wrap: wrap; /* Allow wrapping for smaller screens */
}

.location-box {
    flex: 1;
    background-color: #ececec;
    margin: 0 15px;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    text-align: left; /* Align all text to the left */
}

.location-box p {
    /* font-family: 'Sofia Pro', sans-serif; */
}

h2 {
    font-size: 1.6em;
    margin-bottom: 20px;
    font-weight: bold;
}

.icon-text {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.icon {
    font-size: 24px;
    margin-right: 10px;
}

/* Styling for links to make them appear normal */
.location-box a {
    text-decoration: none; /* Removes underlines */
    color: inherit; /* Inherits the color of the parent element, making it blend seamlessly */
    cursor: pointer;
}

.location-box a:hover {
    text-decoration: underline; /* Adds underline on hover for better UX */
}

/* Media Query for smaller screens */
@media (max-width: 768px) {
    .step3-container {
        flex-direction: column;
        align-items: center;
    }

    .location-box {
        width: 90%; /* Adjust as per your requirement */
        margin-bottom: 20px; /* Space between the boxes when stacked */
    }
}
