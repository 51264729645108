.servicesContainer {
  width: 60%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
}

.serviceItem {
  width: 100%;
  background-color: #ececec;
  margin: 30px 0;
  padding: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 10px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.5s ease; /* Add this line for a smooth transition */
  overflow: hidden;
}

.serviceImage {
  width: 250px;
  height: 250px;
  border-radius: 10px;
  object-fit: cover;
  margin-right: 20px; 
}

.serviceText button {
  border: none;
  background: none;
  color: #f09797;  /* you can change the color as you prefer */
  cursor: pointer;
  text-decoration: underline;
  padding: 0;
  font-family: inherit;
  font-size: inherit;
}


.serviceText h2 {
  font-size: 2.5em;
  margin-bottom: 15px; /* Adjust this to decrease the space between h2 and p */
}

.serviceText p {
  font-size: 1.2em;
  text-align: left;
  max-width: 100%;
  margin-top: 0; /* Adding this line will eliminate any default top margin on the paragraph */
  margin-bottom: 10px;
  line-height: 1.5;
  font-size: 20px;
}

.servicesButton {
  background-color: #f09797;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 1em;
  font-weight: bold; 
  margin-top: 10px;
  cursor: pointer;
}

.collapsed p {
  max-height: 300px; /* Adjust this value based on your needs */
  overflow: hidden;
  transition: all 0.5s ease; /* Transition effect */
}

.expanded p {
  max-height: 1000px; /* Adjust this value based on your needs */
  transition: max-height 0.5s ease; /* Transition effect */
}

/* ... existing styles ... */

/* Media query for screen sizes under 800px */
@media (max-width: 800px) {

  .servicesContainer {
    width: 90%;  /* Adjust width to 90% for smaller screens */
  }

  .serviceItem {
    flex-direction: column;  /* Stack the image above the text */
  }

  .serviceImage {
    width: 100%;  /* Image takes up full width of its container */
    height: auto;  /* Adjust the height to maintain aspect ratio */
    margin-right: 0;  /* Remove margin-right */
    margin-bottom: 20px;  /* Add some space below the image */
  }
}
