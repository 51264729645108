.meet-the-team {
  text-align: center;
  width: 60%;
  margin: 0 auto;
  margin-bottom: 100px;
}

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 80px; 
  justify-content: center; /* This will center the grid items */
  padding: 20px;
}


.grid-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.team-member-image {
  width: 100%;
  max-width: 300px; /* Added this to prevent image from becoming too large */
  height: 300px;
  object-fit: cover;
  border-radius: 20px;
}

.grid-item h4 {
  margin: 10px 0;
  font-size: 1.5em;
}

.grid-item p {
  font-size: 1em;
  color: #666;
}
