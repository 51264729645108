.form-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.custom-form {
    width: 100%;
    background: #bfbfbf;
    padding: 30px;
    border-radius: 10px;
    box-sizing: border-box; /* Include padding and border in an element's total width and height */
}

.form-title {
    text-align: center;
    font-weight: bold;
}

.form-description {
    margin-bottom: 20px;
}

.form-input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-bottom: 10px;
    box-sizing: border-box; /* Include padding and border in an element's total width and height */
    resize: none;
}

.half-width {
    width: 48%;
    box-sizing: border-box; /* Include padding and border in an element's total width and height */
}

.form-row {
    display: flex;
    justify-content: space-between;
}

.form-input::placeholder {
    color: #aaa; /* lighter font color */
    opacity: 1; /* Firefox */
}

.form-input:-ms-input-placeholder {
    color: #aaa;
}

.form-input::-ms-input-placeholder {
    color: #aaa;
}

.error-message {
    color: red;
    font-size: 12px;
}

.form-submit {
    display: block;
    width: 100%;
    padding: 10px;
    border: none;
    background: #fff; /* White background */
    color: grey; /* Pink text color */
    border-radius: 5px;
    margin-top: 10px;
    cursor: pointer;
}

/* Hover effect */
.form-submit:hover {
    background: grey;
    color: #fff;
}

textarea.form-input {
    height: 100px; /* You can adjust this value as needed */
    box-sizing: border-box; /* Include padding and border in an element's total width and height */
}

.radio-buttons {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-color: #bfbfbf;
}

.radio-option {
    margin-bottom: 10px;
}

.form-label {
    text-align: left;
    display: block;
    margin-bottom: 10px;
}

@media (max-width: 800px) {
    .radio-buttons {
        align-items: flex-start;
        justify-content: flex-start;
    }
    
    .radio-option {
        text-align: left; /* Ensures the text within the radio option is left-aligned */
        width: 100%; /* Take up the full width available to prevent unexpected line breaks */
    }
    
    .radio-option input[type="radio"] {
        float: left;
        margin-right: 5px;
    }

    .radio-option label {
        display: inline;
    }
}
