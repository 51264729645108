.parent-container {
    display: flex;
    justify-content: center;
    margin-bottom: 100px;
}

.steps-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 90%;
    gap: 50px;
    animation: fadeIn 1s ease-in-out forwards;
}

.steps-container > * {
    animation-delay: 0.5s; /* This ensures that the children fade in slightly after the parent */
}

.steps-container > * {
    flex: 1;
}

.right-steps {
    display: flex;
    flex-direction: column; /* Stack StepTwo and StepThree on top of each other */
    gap: 20px; /* Space between StepTwo and StepThree */
    flex: 1;
}

@media (max-width: 800px) {
    .steps-container {
        flex-direction: column;
        align-items: center;
        gap: 20px;  /* Adjust as needed for vertical spacing */
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
