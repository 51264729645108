.serviceheader {
  height: 200px;
  background-image: url('../Assets/finalheader.gif');
  background-repeat: repeat-x;
  background-size: auto 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0; /* Start hidden */
  animation: bgFadeIn 1s forwards; /* Apply the animation */
}

.serviceheader h1 {
  color: #242223; /* Here's the font color change */
  font-weight: bold;
  font-size: 50px;
  font-style: italic;
  font-family: 'Verdana', sans-serif;
  opacity: 0; /* Start hidden */
  animation: fadeIn 1s forwards; /* Apply the animation */
}

@keyframes fadeIn {
  0% {
    transform: translateY(-50px); /* Start from 50px above the original position */
    opacity: 0;
  }
  100% {
    transform: translateY(0); /* End at the original position */
    opacity: 1;
  }
}

@keyframes bgFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
