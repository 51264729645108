.my-container {
    background-image: url('../Assets/background.jpg');
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 100px 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;
  }
  
  .my-text {
    font-size: 70px;
    color: white;
    margin-bottom: 0;
    margin-top: 0;
  }
  
  .my-paragraph {
    color: white;
    font-size: 24px; /* Increased font size */
    margin-top: 10px;
    margin-bottom: 20px;
  }
  
  .my-button {
    display: inline-block;
    text-decoration: none;
    color: white;
    background-color: #f09797;
    margin-top: 10px;
    padding: 20px;
    width: 300px;
    font-size: 24px;
    cursor: pointer;
    border: none;
    border-radius: 25px;
    transition: background-color 0.3s, transform 0.3s;
  }
  
  .my-button:hover {
    background-color: #db8080;
    transform: scale(1.1);
  }
  
  @media (max-width: 800px) {
    .my-text {
      font-size: 50px;
    }

    .my-paragraph {
      width: 90%;
    }
  }