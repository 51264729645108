.greeting {
    text-align: left;
    width: 90%;
    margin: 0 auto;
    animation: fadeInFromLeft 1s ease-in-out;
}

.greeting__title {
    font-size: 3em;
    font-weight: bold;
    margin-top: 30px; 
}

.greeting__subtitle {
    font-size: 2em;
    font-weight: normal;
}

.barGif {
    width: 200px;
    height: 15px;
    border-radius: 10px;
    margin-top: 30px;
    margin-bottom: 30px;
}

@keyframes fadeInFromLeft {
    0% {
        transform: translateX(-100%);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}
