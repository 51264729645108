.faq-wrapper {
  display: flex;
  width: 60%;
  margin: auto;
  margin-bottom: 100px;
}

.faq-container {
  flex: 2;
  margin: 20px;
}

.faq-container h1 {
  text-align: center;
  font-size: 2.2em;
}

.faq-item {
  padding: 5px; /* Reduced padding value */
  margin: 2px 0; /* Reduced margin values */
}

.faq-question {
  font-size: 1.7em;
  cursor: pointer;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  text-align: left; /* Add this line to align text to the left */
  justify-content: flex-start; /* Align content to the left */
}

.faq-icon {
  font-size: 1.5em; /* Increase the font size of the icon */
  font-weight: bold; /* Make the icon bold */
  margin-right: 10px;
}

.faq-answer {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease, opacity 0.5s ease;
  margin: 10px 0;
  opacity: 0;
  text-align: left;
  margin-left: 50px;
  line-height: 1.5;
  font-size: 20px;
}

.faq-answer.active {
  max-height: 200px;
  opacity: 1;
}

.appointment-container {
  flex: 1;
  border: none; /* Remove the border */
  padding: 15px;
  margin: 20px;
  border-radius: 10px;
  background-color: #ececec;

  /* Add the following styles for vertical centering */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.appointment-container h2 {
  margin: 0 0 30px 0; /* Add 30px margin to the bottom */
  padding: 0;
  color: #333;
  font-size: 2.5em; /* Increase the header font size */
  text-align: center; /* Center the text horizontally */
}

.appointment-container p {
  margin: 5px 0;
  font-size: 20px; /* Set the font size to 20px */
  text-align: center; /* Center the text horizontally */
}


@media only screen and (max-width: 800px) {
  .faq-wrapper {
      flex-direction: column;
      width: 100%;
      padding: 0 20px; /* Optional padding on sides for some breathing space */
  }

  .faq-container, .appointment-container {
      margin: 0 auto; /* Horizontal centering */
      max-width: 90%; /* Optional max-width for better readability */
  }

  .appointment-container {
      padding-top: 50px;
      padding-bottom: 50px;
  }
}
