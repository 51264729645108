* {
    box-sizing: border-box;
}

.footer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background-color: #ececec;
    color: #333;
    width: 100%;
    flex-direction: column;
}

.footer__content {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 1200px;
}

.footer__social-icons {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px; /* space between icons */
    margin-top: 30px;
}

.footer__social-icon {
    font-size: 40px;
}

.footer__column {
    flex: 1;
    padding: 20px;
    text-align: center;
}

.footer__column h2 {
    margin-bottom: 10px;
    font-weight: bold;
}

.footer__column a {
    color: inherit;
    text-decoration: none;
    display: block;
    padding: 5px 0;
    line-height: 2;
}

.footer__copyright {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    width: 100%;
    max-width: 1200px;
}

.footer__developed {
    text-align: right;
}

.footer__developed a {
    color: inherit;
    text-decoration: none;
}

.footer__location {
    text-align: left;
}

.footer__location-item {
    display: flex;
    align-items: center;
    margin-bottom: 15px; /* Adding 10px margin to the bottom */
    line-height: 1.2; 
}

.footer__icon {
    margin-right: 5px;
    flex-shrink: 0; /* Prevents the icon from shrinking */
    font-size: 24px; 
}

.footer__line {
    height: 1px;
    background-color: black;
    margin: 30px auto 30px; /* 15px margin to the top and 30px margin to the bottom */
    width: 50%;
}

a.footer__link {
    color: inherit;
    text-decoration: none;
    line-height: 1.2; /* You may adjust this value */
}

.footer__copyright-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .footer__legal-links {
    display: flex;
    gap: 1rem;
  }

@media (max-width: 800px) {
    .footer__content {
        flex-direction: column;
    }

    .footer__column {
        width: 100%;
        padding: 10px 0;
    }

    .footer__copyright {
        flex-direction: column;
        text-align: center;  /* Centering the text */
        padding: 10px 0;     /* Adding some padding for spacing */
    }

    .footer__developed {
        text-align: center;
        margin-top: 10px;    /* Adding margin for spacing */
    }

    .footer__location {
        text-align: center;
    }

    .footer__location-item {
        justify-content: center;
    }
}

