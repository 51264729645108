.doctors-container {
    width: 60%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .doctor-card {
    width: 100%;
    background-color: #ececec;
    margin: 30px 0;
    padding: 20px;
    display: flex;
    flex-direction: row; /* Changed to row */
    align-items: center; /* Align items vertically */
    border-radius: 10px;
  }
  
  .doctor-image {
    width: 250px;  /* Increased size */
    height: 250px; /* Increased size */
    border-radius: 10px; /* Rounded corners */
    margin-right: 20px; 
  }
  
  .doctor-details {
    display: flex;
    flex-direction: column;
    justify-content: center; /* Center text vertically */
    align-items: flex-start; /* Align text to the left */
    margin-left: 20px; /* Added margin to bring text closer to the image */
  }
  
  .doctor-name {
    font-size: 2.5em;
    margin: 10px 0;
  }

  .doctor-bio {
    font-size: 1.2em;
    text-align: left;
    max-width: 500px;
    margin-bottom: 10px; /* Added space below bio */
  }

  .bio-button {
    background-color: #f09797;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 1em;
    font-weight: bold; 
    margin-top: 10px;
    cursor: pointer;
  }

  /* Responsive styles for screens smaller than 800px */
  @media (max-width: 800px) {
    .doctors-container {
        width: 90%;
    }
  
    .doctor-card {
        flex-direction: column;
        align-items: center;
    }

    .doctor-image {
        width: 100%; 
        height: auto;
        margin-right: 0;
        margin-bottom: 20px; 
    }
  
    .doctor-details {
        align-items: flex-start; 
        margin-left: 0;
        width: 100%;
    }

    .doctor-name, .bio-button {
        text-align: left;
    }
}