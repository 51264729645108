.step-two {
    width: 100%;
}

.step-two h2 {
    text-align: center;
    font-weight: bold;
}

.step-two ul {
    padding: 20px;
}

.step-two ul li {
    list-style-type: disc;
    margin-bottom: 10px;
    text-align: left;
    font-weight: bold; /* make the list items bold */
}

.step-two .pdf-link {
    color: black;           /* Sets the link color to black */
    text-decoration: none;  /* Removes the underline */
}

/* Optional: If you want to add some visual feedback for when a user hovers over the link */
.step-two .pdf-link:hover {
    text-decoration: underline; /* Adds an underline when hovered */
}

.finance-container,
.insurance-container {
    background-color: #868686;
    color: white;
    padding: 10px 20px; /* Reduced padding at the top and bottom, unchanged on the sides */
    margin: 20px 0;
    border-radius: 10px; /* Rounded corners */
    cursor: pointer; /* Change the cursor to a pointer when hovering over the containers */
}

.step-two p.text-left {
    text-align: left;
}

.finance-container h3,
.insurance-container h3 {
    font-weight: bold;
    text-align: left; /* Aligns the text to the left */
}

.finance-container p,
.insurance-container p {
    text-align: left;
}

.insurance-container {
    margin-top: 30px;
}
