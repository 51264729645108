@font-face {
  font-family: 'Sofia';
  src: url('./Pages/fonts/SofiaProMedium.eot'); /* IE9 Compat Modes */
  src: url('./Pages/fonts/SofiaProMedium.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./Pages/fonts/SofiaProMedium.woff2') format('woff2'), /* Super Modern Browsers */
       url('./Pages/fonts/SofiaProMedium.woff') format('woff'), /* Pretty Modern Browsers */
       url('./Pages/fonts/SofiaProMedium.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./Pages/fonts/SofiaProMedium.svg#SofiaProMedium') format('svg'); /* Legacy iOS */
}

html, body, button, input, select, textarea {
  font-family: 'Sofia', sans-serif !important;
}


.App {
  text-align: center;
}

body, button, input, select, textarea {
  font-family: 'Sofia', sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Sofia', sans-serif;
}

/*
GREYS

superdark
868686

dark
bfbfbf

light
ececec
*/