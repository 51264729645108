@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}


@keyframes fadeInImage1 {
    from { opacity: 0; }
    to { opacity: 1; }
}

@keyframes fadeInImage2 {
    from { opacity: 0; }
    to { opacity: 1; }
}

@keyframes fadeInImage3 {
    from { opacity: 0; }
    to { opacity: 1; }
}

@keyframes fadeInImage4 {
    from { opacity: 0; }
    to { opacity: 1; }
}

@keyframes fadeInFromLeft {
    from {
        opacity: 0;
        transform: translateX(-100px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

.about-image, .about-content {
    animation: fadeInFromLeft 1s ease-in-out forwards;
}

.cred-images img:nth-child(1) {
    animation: fadeInImage1 1s 0.25s ease-in-out forwards;
}

.cred-images img:nth-child(2) {
    animation: fadeInImage2 1s .5s ease-in-out forwards;
}

.cred-images img:nth-child(3) {
    animation: fadeInImage3 1s .75s ease-in-out forwards;
}

.cred-images img:nth-child(4) {
    animation: fadeInImage4 1s 1s ease-in-out forwards;
}






.fade-in {
    animation: fadeIn 1s ease-in-out forwards;
}

.about {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 90vh;
    margin: 0 auto;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    /* background-image: url(${backgroundImage}); */
    
}
  

.about-content-image {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 0 0 50%;
    margin-left: 100px;
}

.about-video {
    flex: 0 0 50%;
}

.about-content {
    text-align: center;
}

.about-content p {
    font-size: 40px;  /* Adjust to desired size */
    margin-top: 20px;
}

.about-buttons {
    margin-top: 30px;
}

.about-buttons button, .about-buttons a {
    display: inline-block;
    text-decoration: none;
    color: inherit;
    margin: 0 10px;
    padding: 20px;
    width: 250px;
    font-size: 24px;  /* Increased font size */
    cursor: pointer;
    border: none; /* Remove borders */
    border-radius: 25px; /* Add border radius for rounded corners */
}

.about-buttons a:hover {
    transform: scale(1.10);
    transition: transform 0.3s ease;
}

.about-buttons .contact-button {
    background-color: #f09797;
}

.about-buttons .services-button {
    background-color: #ececec;
}

.about-image img, .about-video video {
    width: 100%;
    height: auto;
}

.cred-images {
    display: flex;
    justify-content: center; /* This will center the images */
    align-items: center; /* Ensures vertical alignment if there are discrepancies in height */
    width: 100%;
    margin-bottom: 0;
    margin-top: 10px;
}


.cred-images img {
    width: 100px;
    height: auto;
    margin-right: 30px;
    margin-bottom: 10px;
    opacity: 0;
}

.cred-images img:last-child {
    margin-right: 0; /* Remove margin from the last image */
}

.heart-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%; /* take full width */
}

.heart-model {
    margin-right: 100px;
}

@media (max-width: 800px) {
    .heart-container {
        display: none;
    }

    .about {
        display: flex; 
        flex-direction: column; /* Add flex direction column */
        justify-content: center; /* Center children vertically */
        align-items: center; /* Center children horizontally */
        margin: 0;      
        width: 100%;    
        height: auto; 
        
    }

    .about-content-image {
        margin-left: 0; 
        margin: 50px 0;
    }

    .cred-images {
        margin-bottom: 30px;
    }

    .cred-images img {
        max-width: 100px;
        width: 18%; 
        margin-right: 4%; 
    }

    .cred-images img:last-child {
        margin-right: 0; 
    }

    .about-image img {
        width: 90%;
        margin: 0 auto;
        display: block;
    }

    .about-content p {
        font-size: 6vw; 
        white-space: nowrap; 
        overflow: hidden; 
        text-overflow: ellipsis; 
        max-width: 100%; 
    }

    .about-buttons {
        display: flex;
        flex-direction: column; 
        justify-content: center; 
        align-items: center;     
    }

    .about-buttons button, .about-buttons a {
        font-size: 24px;
        flex: none;              
        width: 100%;             
        margin: 15px 0;          
    }

    .about-buttons button:first-child, .about-buttons a:first-child {
        margin-top: 0;           
    }

    .about-buttons button:last-child, .about-buttons a:last-child {
        margin-bottom: 0;        
    }
} 

