@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-in {
  animation: fadeIn 1s forwards;
}

.container {
  width: 90%;
  max-width: 90vw;
  margin: 0 auto;
  display: flex;
  font-family: Arial, sans-serif;
  margin-bottom: 100px;
}

.locations {
  width: 33%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.address {
  box-sizing: border-box;
  background-color: #ececec;
  border-radius: 8px;
  margin-bottom: 1em;
  transition: background-color 0.3s ease-in; /* Added transition */
}

.address:hover {
  background-color: #d6d6d6; /* In-between color */
}

.address.active {
  background-color: #bfbfbf;
}

.address h2 {
  margin-top: 0px;
  margin-bottom: 20px;
  font-size: 2em; /* Increased font size */
  line-height: 1;
}

.address p, .address h3 {
  margin-top: 0px;
  margin-bottom: 20px;
  font-size: 1.5em;
  line-height: 1;
}

.button-link {
  text-decoration: none;
  color: black;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: flex-start;
  justify-content: center;
  border: none;
  background-color: inherit;
  cursor: pointer;
  padding: 1em;
  border-radius: 8px;
  text-align: left;
}

.mapContainer {
  flex-grow: 1;
  margin-left: 30px;
}

.map {
  width: 100%;
  height: 450px;
  border: none;
}

.hours-container {
  text-align: left; /* This was already aligned to the left */
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-in-out;
}

.hours-container.active {
  max-height: 400px; /* You may need to adjust this value to fit your content */
}

.icon-text-container {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  text-align: left;
}

.icon-text-container .icon {
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.icon-text-container p,
.icon-text-container h3 {
  margin: 0;
  flex-grow: 1;
}

.icon {
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  font-size: 24px;
}

.phone-link,
.address-link {
  color: inherit;
  text-decoration: none;
}

.phone-link:hover,
.address-link:hover {
  text-decoration: underline;
}

.hours-container h3 {
  text-align: left;
}

@media (max-width: 700px) {
  .container {
    flex-direction: column;
    align-items: center;
  }

  .locations {
    width: 100%;
    margin-bottom: 20px;
  }

  .address {
    width: 90%;
    margin: 0 auto;
    margin-bottom: 15px;
  }

  .mapContainer {
    margin-left: 0;
    width: 90%;
    margin-bottom: 20px;
    height: 500px;
  }

  .map {
    width: 100%;
    height: 100%; /* Adjust height if necessary */
  }
}