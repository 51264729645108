/* OurServices.css */
.fade-in {
    opacity: 0;
    transform: translateY(20px);
    animation: fadeIn 0.5s forwards;
  }
  
  @keyframes fadeIn {
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  